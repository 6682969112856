/* Node hierarchy component styles */
.node circle {
    fill: rgb(255, 255, 255);
    stroke: rgb(70, 130, 180);
    stroke-width: 3px;
    cursor: pointer;
}

.node text {
    font: 12px sans-serif;
}

.ghostCircle {
    display: none;
}

.ghostCircle.show {
    display: block;
    opacity: 0.5;
    fill: green;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.link {
    fill: none;
    stroke: rgb(204, 204, 204);
    stroke-width: 2px;
}

div.d3-tooltip {
    position: absolute;
    text-align: center;
    max-width: 160px;
    padding: 7px 10px;
    font: 12px sans-serif;
    color: white;
    background: #111;
    border: 0px;
    border-radius: 2px;
    pointer-events: none;
    margin-left: 25px;
    margin-top: -19px;
}

div.d3-tooltip:before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    margin-top: 1px;
    margin-left: -8px;
    border-right: 8px solid #111;
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
}

/* d3-context-menu styles */
.d3-context-menu {
    position: absolute;
    display: none;
    color: rgb(82, 81, 81);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    min-width: 150px;
    border: 1px solid rgb(212, 212, 212);
    z-index: 1200;
}

.d3-context-menu ul {
    list-style-type: none;
    margin: 4px 0px;
    padding: 0px;
    cursor: default;
}

.d3-context-menu ul li {
    padding: 4px 16px;
}

.d3-context-menu ul li:hover {
    background-color: rgb(218, 220, 228);
    color: rgb(0, 0, 0);
}
